.input {
  position: relative;

  input {
    display: block;
    color: #f5f3f7;
    border: #24182f solid 1px;
    background-color: #1c1326;
    border-radius: 6px;
    outline: none;
    padding: 11px 11px;
    font-size: 14px;
    line-height: 24px;
    width: 100%;

    ::placeholder {
      font-size: 14px;
      line-height: 24px;
    }
  }

  &:after {
    position: absolute;
    right: 5px;
    width: 38px;
    height: 38px;
    content: url('../../assets/icon-check.svg');
    top: 5px;
    border-radius: 3px;
    background: transparent;
    padding: 7px;
    opacity: 0;
  }
}

.normal {
  input {
    border-color: #24182f;
  }
}

.focused {
  input {
    border-color: #24182f;
  }
}

.frozen {
  textarea,
  input {
    opacity: 0.5;
    border: #24182f solid 1px;
  }
}

.disabled,
.loading {
  textarea,
  input {
    background: #f2f2f2;
    border-color: #24182f;
    pointer-events: none;
    opacity: 0.7;
  }
}

.loading {
  &::after {
    border-color: #24182f;
    transition: opacity 0.3s;
    opacity: 1;
    content: url('../../assets/icon-loader.svg');
  }
}

.valid {
  textarea,
  input {
    border-color: var(--color-affirmative);
  }
  &::after {
    transition: opacity 0.15s;
    opacity: 1;
  }
}

.invalid {
  textarea,
  input {
    border-color: var(--color-warning);
  }

  &::after {
    transition: opacity 0.15s;
    opacity: 1;
    content: url('../../assets/icon-error.svg');
  }
}
