.input {
  position: relative;

  input {
    display: block;
    color: #f5f3f7;
    border: #24182f solid 1px;
    background-color: #1c1326;
    border-radius: 6px;
    outline: none;
    padding: 11px 11px;
    font-size: 14px;
    line-height: 24px;
    width: 100%;

    &.error {
      border-color: var(--color-warning);
    }

    ::placeholder {
      font-size: 14px;
      line-height: 24px;
    }
    &.disabled {
      opacity: 0.7;
    }
  }
}

.textarea {
  textarea {
    background-color: #1c1326;
    color: #f5f3f7;
    border: 1px solid #24182f;
    border-radius: 6px;
    padding: 7px;
    width: 100%;

    &.error {
      border-color: var(--color-warning);
    }

    ::placeholder {
      font-size: 14px;
      line-height: 24px;
    }
    &.disabled {
      opacity: 0.7;
    }
  }
}
