.btn {
  border-radius: 6px;
}
.primary {
  background-color: var(--color-accent);

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.secondary {
  border: 1px solid #e42575;

  &:disabled {
    border-color: #bbb;
    color: #bbb;
    cursor: not-allowed;
  }
}
