.input {
  display: block;
  margin-bottom: 1.5rem;

  label {
    display: block;
    font-style: normal;
    line-height: 24px;
    text-transform: capitalize;
    color: var(--color-text);
    margin-bottom: 6px;
  }
}

.preview {
  color: var(--color-text);
  display: inline-block;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 16px;
}

.error {
  font-size: 14px;
  line-height: 16px;
  color: var(--color-warning);
  padding: 8px;
}

.description,
.description p {
  margin-top: 6px;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-text-secondary);
}
