.loader {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 64px;
    height: 64px;
    fill: #f5f2f7;
  }
}
