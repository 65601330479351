.header {
  width: 100%;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  .ma-logo {
    align-self: flex-start;
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;

    &--ma {
      padding: 24px 16px 23px 0px;
    }

    &--me {
      padding: 40px 16px 23px 0px;
    }
  }

  .menu {
    display: flex;
    justify-content: flex-end;
    margin-right: 8px;


    &__item:hover {
      color: #E42575;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: var(--nav-height);
      font-size: 14px;
      margin-left: 2rem;
    }
  }
}

screen lg {
  .header {
    margin-bottom: 42px;

    .menu__item {
    }
  }
}
