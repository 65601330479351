.calendar {
  background-color: #1c1929;
  border: 1px solid #2b273f;
}
.day {
  color: white;
  &:hover {
    background-color: var(--color-accent);
    opacity: 0.8;
  }
}

.error {
  border: 1px solid var(--color-accent);
  border-radius: 6px;
}

:global {
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__header {
    background-color: #1c1929;
    border-bottom: 1px solid #2b273f;
    color: var(--color-text);
    .react-datepicker__current-month {
      color: var(--color-text);
    }
    .react-datepicker__day-name {
      color: var(--color-text);
    }
  }
  .react-datepicker__day--selected {
    background-color: var(--color-accent);
  }
  .react-datepicker__day--disabled {
    color: #665676;
  }

  .react-datepicker-time__caption {
    color: var(--color-text);
  }
  input.react-datepicker-time__input {
    padding: 4px 8px;
    background-color: #120c18;
  }
}
