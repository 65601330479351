@import url('http://fonts.cdnfonts.com/css/inter');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --nav-height: 114px;
  --color-text: #f5f2f7;
  --color-text-secondary: #a89db4;
  --color-accent: #e42575;
  --color-warning: #ffaa00;
  --color-affirmative: #00d181;
  --color-positive-accent: #00d181;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.5;
  color: #f5f3f7;
  background-color: #120c18;
}

h1 {
  letter-spacing: -1.5px;
  line-height: 1;
  margin-bottom: 1rem;
  font-size: 2.25rem;
  font-weight: 700;
}

h2 {
  font-size: 1.75rem;
  font-weight: bold;
}

p {
  font-weight: 300;
  font-size: 20px;
}

.page {
  animation-duration: 0.6s;
  animation-name: fadein;
  margin-bottom: 4rem;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.container {
  margin: 0 auto;
  padding: 0 32px;
}

.profile-page,
.home-page,
.editor-page {
  min-height: calc(100vh - 128px - 114px);
}

.form-container {
  @apply relative px-3 py-2 border border-gray-300 rounded-md shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600;
}

.form-label {
  @apply text-lg text-gray-800;
}

.form-field {
  @apply block w-full p-0 text-gray-800 placeholder-gray-500 border-0 outline-none focus:ring-0 sm:text-sm;
}

.striked {
  text-decoration: line-through;
}

.ch-button,
.ma-button {
  display: inline-block;
  min-width: 138px;
  height: 48px;
  background: var(--color-accent);
  padding: 12px 16px;
  text-align: center;
  border-radius: 4px;
  color: var(--color-text);
  line-height: 150%;
  font-size: 1rem;
}
.ch-button:disabled,
.ch-button[disable='disabled'],
.ma-button:disabled,
.ma-button[disable='disabled'] {
  cursor: wait;
  opacity: 0.5;
}

.muted {
  color: var(--color-text-secondary);
}

@screen lg {
  .container {
    max-width: 1240px;
  }
}

.dark-scroll-bar {
  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    // background: #393812;
    // background: #4e4670;
    background: #2a2a36;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    height: 20%;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

.input-dark-1 {
  background-color: #1c1326 !important;
  border: 1px solid transparent !important;
  border-radius: 8px;
  &:focus {
    border: 1px solid #4d3d5c;
    background-color: #1c1326;
    outline: none;
  }
}

.ch-link,
.ma-link {
  color: var(--color-accent);
  cursor: pointer;
}

/**
 * Tailwind variants workaround
 * JIT is broken, until we find a way to fix it
 * let's add all tailwind classes that do now work here manually
 * making sure we mimic classes behavior 100% correctly
 * so when we enable JIT we can safely remove the below classes
 * without breaking the UI
 *
 * TODO: remove when tailwind JIT will be fixed
 */

.w-\[680px\] {
  width: 680px;
}

.w-1\/2 {
  width: 50%;
}

.first\:rounded-t-md:first-child {
  border-top-left-radius: 0.375rem; /* 8px */
  border-top-right-radius: 0.375rem; /* 8px */
}

.last\:rounded-b-md:last-child {
  border-bottom-right-radius: 0.375rem; /* 8px */
  border-bottom-left-radius: 0.375rem; /* 8px */
}

.last\:border-b:last-child {
  border-bottom-width: 1px;
}

.border-\[\#2b2037\] {
  border-color: #2b2037;
}

.bg-\[\#1c1326\] {
  background-color: #1c1326;
}

.text-warning,
.text-\[\#ffaa00\] {
  color: var(--color-warning);
}

.text-\[\#998ca6\] {
  color: #998ca6;
}
